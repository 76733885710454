.navbar {
  position: fixed;
  background: linear-gradient(
    90deg,
    rgba(28, 27, 27, 0) 0%,
    rgba(26, 23, 23, 0) 100%
  );
  height: 100px;
  width: 100vw;
  font-size: 1.2rem;
  transition: 0.3s ease-in-out;
  z-index: 3;
}

.navbar.scrolled {
  background: rgba(255, 255, 255, 0.965);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.scrolled a {
  color: black;
}

.scrolled a.nav-links:hover {
  border: 1px solid black;
}

.navbar-logo.scrolled {
  filter: invert(100%);
}

.navbar-logo {
  justify-self: start;
  margin-left: 1.3em;
  cursor: pointer;
  margin-top: 10px;
  width: 11em;
  filter: invert(0%);
}

.navbar-container {
  display: flex;
  justify-content: space-around;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: center;
  width: 85vw;
  justify-content: flex-end;
  margin-right: 1.5em;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 100px;
}

.nav-links {
  color: white;
  text-decoration: none;
  font-weight: 400;
  padding: 1rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0);
  transition: 0.4s ease-in-out;
  letter-spacing: 1px;
}

.nav-links:hover {
  border: 1px solid white;
}

.fa-bars {
  color: #fff;
}

.fa-bars.scrolled {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  transition: all 240ms ease-in;
}

@media screen and (max-width: 1024px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    position: absolute;
    top: 93px;
    opacity: 0;
    margin-left: 0;
  }

  .nav-menu.active {
    visibility: visible;
    opacity: 1;
    background: #ffffff;
    left: 0;
    right: 0;
    transition: visibility 0s, opacity 0.3s linear;
    z-index: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    color: black;
    font-weight: 100;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .menu-icon {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(-100%, 80%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-xmark {
    color: #fff;
    font-size: 2rem;
  }

  .scrolled i {
    color: black;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
}

@media screen and (max-width: 768px) {
  .navbar-logo {
    font-size: 1rem;
  }
  .menu-icon {
    transform: translate(-100%, 85%);
    font-size: 1.6rem;
  }
}
