.footer {
  background-color: #eaeaea;
}

.footer-container {
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-around;
  align-self: center;
  position: relative;
  z-index: 1;
}

.footer-bottom {
  padding: 20px 32px 20px 32px;
  display: flex;
  justify-content: center;
}

.footer-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 100;
}

.footer-bottom-container > a {
  text-decoration: none;
  font-family: 'Rock Salt', cursive;
  color: black;
}

.footer-img {
  width: 210px;
}

.masterclassfilm-logo {
  width: 100%;
  margin-bottom: 1em;
  filter: grayscale(100%);
}

.kajutex-logo {
  width: 100%;
  filter: invert(100%);
}

.footer-links {
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-links a {
  text-decoration: none;
  color: black;
  font-weight: 100;
  font-size: 15px;
}

.footer-social {
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-social a {
  text-decoration: none;
  color: black;
}

.fa-brands,
.fa-paper-plane {
  font-size: 25px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .footer-links {
    align-items: center;
  }

  .footer-bottom {
    padding: 20px;
  }

  .footer-bottom-attribution {
    margin: 0;
  }

  .footer-img {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-text,
  .footer-links,
  .footer-social {
    padding: 20px 0;
    margin-bottom: 20px;
  }
}
