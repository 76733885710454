.hero-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: -3;
  background-color: black;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  position: relative;
  z-index: -3;
}

.hero-container > h2 {
  color: white;
  font-family: "Inconsolata", monospace;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 70px;
  letter-spacing: 23px;
  padding-top: 180px;
}

@media screen and (max-width: 1024px) {
  .hero-container {
    flex-direction: row;
  }

  .hero-container > h2 {
    font-size: 34px;
    letter-spacing: 10px;
    padding-left: 9px;
  }
}

@media screen and (max-width: 280px) {
  .hero-container > h2 {
    font-size: 21px;
  }
}
