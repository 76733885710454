.cool-border-1 {
  --b: 0.1em; /* border width */
  --c: 3em; /* corner size */
  --r: 1em; /* corner rounding */
  position: relative;
  margin: 150px 70px 50px;
  border: solid var(--b) transparent;
  padding: 1em;
  max-width: 29em;

  &::before {
    position: absolute;
    z-index: -1;
    inset: calc(-1 * var(--b));
    border: inherit;
    border-radius: var(--r);
    background: linear-gradient(orange, deeppink, purple) border-box;
    --corner: conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0)
      0 0 / calc(100% - var(--c)) calc(100% - var(--c)) border-box;
    --inner: conic-gradient(red 0 0) padding-box;
    -webkit-mask: var(--corner), var(--inner);
    -webkit-mask-composite: source-out;
    mask: var(--corner) subtract, var(--inner);
    content: "";
  }
}

.cool-border-2 {
  --b: 0.1em; /* border width */
  --c: 3em; /* corner size */
  --r: 1em; /* corner rounding */
  position: relative;
  margin-bottom: 10px;

  border: solid var(--b) transparent;
  padding: 1em;
  max-width: 38em;

  &::before {
    position: absolute;
    z-index: -1;
    inset: calc(-1 * var(--b));
    border: inherit;
    border-radius: var(--r);
    background: linear-gradient(orange, deeppink, purple) border-box;
    --corner: conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0)
      0 0 / calc(100% - var(--c)) calc(100% - var(--c)) border-box;
    --inner: conic-gradient(red 0 0) padding-box;
    -webkit-mask: var(--corner), var(--inner);
    -webkit-mask-composite: source-out;
    mask: var(--corner) subtract, var(--inner);
    content: "";
  }
}
