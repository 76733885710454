.portfolio-gallery {
  min-height: 100vh;
  max-width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 10px 30px 10px;
}

.portfolio-gallery-container {
  max-width: 1800px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 30px 20px;
  margin: 0 30px 0 30px;
}

.portfolio-gallery .video-card {
  background-color: black;
  width: 420px;
  margin: 3rem 1rem 1rem 1rem;
  box-shadow: rgba(46, 95, 240, 0.4) 5px 5px, rgba(46, 95, 240, 0.3) 10px 10px,
    rgba(46, 85, 240, 0.2) 15px 15px, rgba(46, 69, 240, 0.1) 20px 20px,
    rgba(46, 65, 240, 0.05) 25px 25px;
  transition: 800ms;
}

.portfolio-gallery .video-card:hover {
  box-shadow: none;
}

.nametag {
  display: flex;
  justify-content: center;
}

.nametag > h2 {
  font-weight: 100;
  font-size: 1.3em;
  margin-bottom: 50px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .portfolio-gallery-container {
    flex-direction: column;
  }
  .portfolio-gallery .video-card {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-gallery .video-card {
    width: 400px;
    margin: 2rem 0;
  }
}

@media screen and (max-width: 460px) {
  .portfolio-gallery .video-card {
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .portfolio-gallery .video-card {
    width: 220px;
  }
}
