.home,
.o-nas,
.portfolio,
.kontakt {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  overflow-x: hidden;
  max-width: 100vw;
}

.home {
  background-color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.o-nas {
  background: linear-gradient(180deg, #004b5b 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #51bbc1 50.58%, #00a3ff 100%),
    linear-gradient(244.35deg, #e03f3f 0%, #001665 49.48%, #ff0000 100%),
    radial-gradient(100% 233.99% at 0% 100%, #ff0000 0%, #ad00ff 100%),
    linear-gradient(307.27deg, #096f5c 0.37%, #687eb5 50.19%, #8877ce 100%),
    radial-gradient(
      100% 140% at 100% 0%,
      #ff00c7 0%,
      #006c7a 49.48%,
      #760000 100%
    );
  background-blend-mode: overlay, overlay, overlay, difference, color-dodge,
    normal;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 100;
}

.o-nas > h1 {
  letter-spacing: 20px;
  margin-right: -20px;
  text-align: right;
}

.portfolio {
  background: linear-gradient(320.54deg, #00069f 0%, #120010 72.37%),
    linear-gradient(58.72deg, #69d200 0%, #970091 100%),
    linear-gradient(121.28deg, #8cff18 0%, #6c0075 100%),
    linear-gradient(121.28deg, #8000ff 0%, #000000 100%),
    linear-gradient(180deg, #00ff19 0%, #24ff00 0.01%, #2400ff 100%),
    linear-gradient(52.23deg, #0500ff 0%, #ff0000 100%),
    linear-gradient(121.28deg, #32003a 0%, #ff4040 100%),
    radial-gradient(50% 72.12% at 50% 50%, #eb00ff 0%, #110055 100%);
  background-blend-mode: screen, color-dodge, color-burn, screen, overlay,
    difference, color-dodge, normal;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: white;

  text-transform: uppercase;
  font-size: 100px;
  font-weight: 100;
}

.portfolio > h1 {
  letter-spacing: 20px;
  margin-right: -20px;
  text-align: right;
}

.kontakt {
  background: linear-gradient(120deg, #4b0000 0%, #000000 100%),
    linear-gradient(300deg, #1e0000 0%, #0038ff 100%, #0038ff 100%),
    linear-gradient(65deg, #00ffff 0%, #ff00a8 100%),
    linear-gradient(185deg, #ff0000 10%, #1400ff 95%),
    radial-gradient(100% 140% at 100% 0%, #00a3ff 0%, #c10097 100%);
  background-blend-mode: color-dodge, overlay, difference, color-dodge, normal;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 100;
}

.kontakt > h1 {
  letter-spacing: 20px;
  margin-right: -20px;
  text-align: right;
}

.loading {
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden-header {
  opacity: 0;
  filter: blur(5px);
  transition: all 3s;
}

.hidden-images {
  opacity: 0;
  filter: blur(10px);
  transition: 1.4s;
  transform: translateX(-150%);
}

.hidden-offer {
  opacity: 0;
  filter: blur(10px);
  transition: 0.7s;
  transform: translateX(33%);
}

.hidden-icons {
  opacity: 0;
  filter: blur(10px);
  transition: 0.7s;
  transform: translateX(-150%);
}

.oferta-icons {
  width: 70px;
  height: 70px;
}

.hidden-text {
  opacity: 0;
  transition: 2s;
  filter: blur(3px);
}

.hidden {
  opacity: 0;
  transition: 2s;
}

.show {
  opacity: 1;
  transform: translateX(0);
  filter: none;
}

@media screen and (max-width: 1024px) {
  .portfolio,
  .o-nas,
  .kontakt > h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  .portfolio,
  .o-nas,
  .kontakt > h1 {
    font-size: 25px;
  }
}
