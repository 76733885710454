.kontakt-section {
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kontakt-container {
  max-width: 80vw;
  display: flex;
  padding: 20px;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.kontakt-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 60px;
}

.kontakt-container h1 {
  font-size: 30px;
  font-weight: 100;
  padding: 30px 0 20px 0;
}
.kontakt-container p {
  font-size: 20px;
  font-weight: 100;
  padding: 5px;
}

.kontakt-container a {
  text-decoration: none;
  padding-bottom: 5px;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.kontakt-container a:hover {
  border-bottom: 1px solid rgb(94, 90, 90);
  transition: 1s;
}

.bonifacego-map-wrapper {
  width: 700px;
  height: 400px;
}

.bonifacego-map {
  position: relative;
  padding-top: 56.25%;
}

@media screen and (max-width: 1024px) {
  .kontakt-container {
    flex-direction: column;
  }
  .kontakt-wrapper {
    margin-right: 0;
  }
  .bonifacego-map-wrapper {
    width: 600px;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .bonifacego-map-wrapper {
    width: 400px;
  }
}

@media screen and (max-width: 420px) {
  .bonifacego-map-wrapper {
    width: 300px;
  }
}

@media screen and (max-width: 315px) {
  .bonifacego-map-wrapper {
    width: 250px;
  }
}
