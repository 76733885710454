.o-nas-section {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 200px 0 200px 0;
}

.name {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-family: "Roboto", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif, monospace;
}

.name:hover {
  border-bottom: 5px black;
}

.top-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.o-nas--p {
  font-size: 20px;
  font-weight: 100;
  max-width: 50vw;
  padding: 30px;
  text-align: justify;
  cursor: pointer;
}

.o-nas--main-text {
  font-size: 20px;
  font-weight: 100;
  max-width: 50vw;
  text-align: justify;
}

.o-nas--img {
  width: 23em;
  cursor: pointer;
  transition: 800ms;
}

.kaja {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px;
}

.michal {
  box-shadow: rgba(46, 69, 240, 0.4) 5px 5px, rgba(46, 85, 240, 0.3) 10px 10px,
    rgba(46, 111, 240, 0.2) 15px 15px, rgba(46, 111, 240, 0.1) 20px 20px,
    rgba(46, 62, 240, 0.05) 25px 25px;
}

.mateusz {
  box-shadow: rgba(240, 159, 46, 0.4) 5px 5px, rgba(240, 204, 46, 0.3) 10px 10px,
    rgba(240, 175, 46, 0.2) 15px 15px, rgba(240, 185, 46, 0.1) 20px 20px,
    rgba(240, 224, 46, 0.05) 25px 25px;
}

.o-nas--img:hover {
  box-shadow: none;
}

.o-nas-section--item {
  width: 23em;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .section-2--container > p {
    font-weight: 100;
    padding: 5px;
    font-size: 16px;
  }
}

@media screen and (max-width: 680px) {
  .o-nas--p {
    font-size: 15px;
    padding: 0;
  }
  .o-nas--main-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
  .top-container {
    margin: 0 0 80px;
  }
}

@media screen and (max-width: 420px) {
  .o-nas--img {
    width: 18em;
  }
}

@media screen and (max-width: 340px) {
  .o-nas--img {
    width: 15em;
  }
}

@media screen and (max-width: 280px) {
  .section-2--container > p {
    font-size: 14px;
  }
}
